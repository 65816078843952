import React from "react";

import { NavLink } from "react-router-dom";
import ScrollToHashElement from "../ScrollToHash/index.js"
import styled from "styled-components";
import { shade } from "polished";

import LogoHeader from "../Logo";

const Styles = styled.header`
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  background-color: #251f16cc;
  max-height: 10%;

  backdrop-filter: saturate(1.8) blur(5px);

  top: 0;
  z-index: 100;

  #nav-buttons {
    display: inline-flex;
    width: 50%;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    margin-right: 24px;
    background-color: transparent;
  }

  #nav-buttons a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;

    & > .deskSelected{
      background-color: #251f16;
    }

    &:hover {
      background-color: ${shade(0.2, "#251f16")};
    }
  }

  .deskSelected {
    background-color: #251f16;
    font-weight: 700;
  }

  .mobSelected .iconContainer {
    background: #d6249f;
    background: radial-gradient(
      circle at 30% 107%,
      #fdf49773 0%,
      #fdf49773 5%,
      #fd594973 45%,
      #d6249f73 60%,
      #285aeb73 90%
    );
  }

  .containerDropdown {
    width: 100%;
    height: 100%;

    cursor: pointer;

    background-color: transparent;

    &:hover {
      background-color: ${shade(0.2, "#251f16")};
      .dropMenu {
        display: block !important;
      }
    }

    .dropdown {
      background-color: transparent;

      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      span {
        background-color: transparent;
      }
    }
    .dropMenu {
      display: none;
      background-color: #251f16cc;
    }
  }
  #responsiveNavMenu {
    display: none;
    ::-webkit-scrollbar {
      width: 12px;
    }
  }

  @media only screen and (max-width: 720px)  {
    #nav-buttons {
      display: none;
    }
    #logo-header {
      display: none;
    }
    #responsiveNavMenu {
      display: grid;
      grid-auto-flow: column dense;
      overflow: auto;
      overflow-y: hidden;
      background-color: transparent;

      .mobileLinks {
        height: 100px;
        width: 100px;

        margin: 16px 2px 8px 2px;

        display: block;

        background-color: transparent;

        & .iconContainer {
          display: flex;
          margin: 0 auto;
          width: 80px;
          height: 80px;
          border: 2px solid #cca352;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
        }

        & svg {
          background-color: transparent;
          width: 45px;
        }
        & span {
          display: block;
          width: 100%;
          text-align: center;
          font-size: 14px;
          background-color: transparent;
        }
      }
    }
  }
  @media screen and (min-width: 721px) and (max-width: 834px) {
    #nav-buttons {
      display: none;
    }
    #logo-header {
      display: none;
    }
    #responsiveNavMenu {
      display: grid;
      grid-auto-flow: column dense;
      overflow: auto;
      background-color: transparent;

      .mobileLinks {
        height: 120px;
        width: 120px;

        margin: 16px 4px 12px 4px;

        display: block;

        background-color: transparent;

        & .iconContainer {
          display: flex;
          margin: 0 auto;
          width: 100px;
          height: 100px;
          border: 3px solid #cca352;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
        }

        & svg {
          background-color: transparent;
          max-width: 75%;
        }

        & img {
          background-color: transparent;
          filter: invert(99%) sepia(9%) saturate(2279%) hue-rotate(308deg)
            brightness(90%) contrast(118%);
        }

        & span {
          display: block;
          width: 100%;
          text-align: center;
          font-size: 18px;
          background-color: transparent;
        }
      }
    }
  }
`;

export const NavBar = () => {
  return (
    <Styles>
      <ScrollToHashElement />
      <LogoHeader />
      <div id="nav-buttons">
        <NavLink to="/#top" className={({ isActive }) => "nav-link" + (isActive ? " deskSelected" : "")}  end>
          Início
        </NavLink>
        <NavLink
        to="/procedimentos/#top"
        className={({ isActive }) => "nav-link" + (isActive ? " deskSelected" : "")}
        >
          Procedimentos
        </NavLink>
        <NavLink
        to="/produtos/#top"
        className={({ isActive }) => "nav-link" + (isActive ? " deskSelected" : "")}
        >
          SKIN BABY
        </NavLink>
        <NavLink to="/#aboutUs" className="nav-link" >
          Sobre nós
        </NavLink>
        <NavLink
        to="/sobre-mim/#top"
        className={({ isActive }) => "nav-link" + (isActive ? " deskSelected" : "")}
        >
          Sobre mim
        </NavLink>
        <NavLink
        to="/depoimentos/#top"
        className={({ isActive }) => "nav-link" + (isActive ? " deskSelected" : "")}
        >
          Depoimentos
        </NavLink>
        <NavLink to="/#contact" className={({ isActive }) => "nav-link" + (isActive ? " deskSelected" : "")}>
          Contato
        </NavLink>
      </div>
      <div id="responsiveNavMenu">
        <NavLink
          className={({ isActive }) => "mobileLinks" + (isActive ? " mobSelected" : "")}
          to="/#top"
          end
        >
          <div className="iconContainer">
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#cca352" stroke="none">
              <path d="M2495 4625 c-57 -27 -469 -349 -488 -382 -40 -70 -17 -164 53 -210
              37 -26 116 -31 153 -11 12 6 95 67 184 135 l163 124 717 -543 718 -543 3
              -1198 2 -1197 -320 0 -320 0 0 663 c0 719 -1 731 -56 839 -34 67 -135 168
              -202 202 -101 51 -146 56 -542 56 -396 0 -441 -5 -542 -56 -67 -34 -168 -135
              -202 -202 -55 -108 -56 -120 -56 -839 l0 -663 -320 0 -320 0 0 1195 0 1194
              159 122 c172 130 191 154 191 232 0 85 -72 151 -163 150 -34 0 -58 -8 -81 -24
              -17 -12 -221 -166 -453 -342 -391 -296 -423 -322 -439 -363 -40 -104 34 -214
              145 -214 53 0 74 11 212 116 54 41 101 74 104 74 3 0 5 -481 5 -1070 l0 -1070
              -176 0 c-198 0 -225 -7 -272 -68 -39 -51 -39 -133 0 -184 57 -74 -144 -68
              2208 -68 2352 0 2151 -6 2208 68 39 51 39 133 0 184 -47 61 -74 68 -272 68
              l-176 0 0 1075 0 1074 103 -78 c56 -43 118 -88 137 -100 96 -58 225 7 237 120
              7 71 -24 114 -152 212 -746 568 -2013 1520 -2032 1528 -35 13 -83 11 -118 -6z
              m471 -2411 c15 -11 37 -33 48 -48 21 -27 21 -39 24 -697 l2 -669 -480 0 -480
              0 0 654 c0 440 4 664 11 683 6 15 20 39 32 52 46 50 56 51 449 48 353 -2 368
              -3 394 -23z"/>
              </g>
              </svg>
          </div>
          <span>Início</span>
        </NavLink>
        <NavLink
          className={({ isActive }) => "mobileLinks" + (isActive ? " mobSelected" : "")}
          to="/procedimentos/#top"
        >
          <div className="iconContainer">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="64.000000pt"
              height="64.000000pt"
              viewBox="0 0 64.000000 64.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                fill="#cca352"
                stroke="none"
              >
                <path
                  d="M270 545 c-10 -12 -10 -15 3 -15 9 0 18 -10 20 -22 l4 -23 2 23 c0
13 7 22 17 22 14 0 14 3 4 15 -7 8 -18 15 -25 15 -7 0 -18 -7 -25 -15z"
                />
                <path
                  d="M454 529 c-3 -6 0 -18 9 -26 18 -18 37 -9 37 18 0 21 -34 27 -46 8z
m31 -9 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"
                />
                <path
                  d="M182 518 c-15 -15 -16 -32 -2 -23 6 3 10 -3 10 -14 0 -12 5 -21 10
-21 6 0 10 9 10 21 0 11 5 17 10 14 6 -3 10 -2 10 2 0 10 -21 33 -30 33 -3 0
-11 -5 -18 -12z"
                />
                <path
                  d="M240 466 c-20 -8 -63 -29 -95 -47 -75 -43 -53 -49 64 -19 l82 21 114
-31 c63 -16 119 -30 124 -30 6 0 11 15 11 34 0 38 -6 42 -130 71 -78 18 -123
18 -170 1z m218 -31 c48 -14 62 -22 62 -36 0 -11 -2 -19 -4 -19 -2 0 -53 13
-114 29 -106 28 -114 29 -164 15 -75 -20 -81 -16 -23 12 42 21 61 24 115 21
36 -2 93 -12 128 -22z"
                />
                <path
                  d="M205 351 c-33 -16 -73 -41 -90 -54 -25 -21 -28 -29 -19 -44 6 -10 20
-18 30 -18 11 -1 41 -14 67 -29 58 -35 117 -49 165 -40 63 11 152 62 152 87 0
5 -16 -3 -35 -17 -42 -32 -106 -56 -151 -56 -39 0 -74 12 -134 48 -25 14 -53
25 -64 23 -31 -5 -13 22 38 57 122 82 251 76 348 -16 15 -14 31 -22 35 -18 11
11 -42 53 -102 82 -74 35 -161 33 -240 -5z"
                />
                <path
                  d="M276 349 c-41 -10 -126 -58 -126 -71 0 -13 14 -9 40 12 33 26 40 25
40 -4 0 -14 12 -38 26 -55 49 -58 146 -31 160 44 7 41 10 42 41 13 13 -12 26
-19 29 -15 12 12 -51 57 -100 72 -55 17 -55 17 -110 4z m-12 -48 c-10 -48 52
-90 92 -63 24 16 35 51 23 73 -9 17 -8 20 3 17 21 -7 21 -62 0 -88 -27 -33
-87 -33 -113 -1 -19 23 -26 71 -12 84 11 12 13 8 7 -22z m96 14 c16 -19 10
-45 -15 -61 -39 -26 -86 31 -53 64 16 16 53 15 68 -3z"
                />
                <path
                  d="M300 295 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                  d="M243 95 c0 -8 9 -15 19 -15 10 0 18 7 18 15 0 8 -8 15 -18 15 -10 0
-19 -7 -19 -15z"
                />
              </g>
            </svg>
          </div>
          <span>Procedimentos</span>
        </NavLink>
        <NavLink
          className={({ isActive }) => "mobileLinks" + (isActive ? " mobSelected" : "")}
          to="/produtos/#top"
        >
          <div className="iconContainer">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#cca352" stroke="none">
            <path d="M754 4846 c-17 -13 -19 -29 -22 -145 -4 -128 -3 -131 22 -156 17 -17
            36 -25 61 -25 l35 0 0 -110 0 -110 -38 0 c-78 0 -77 5 -80 -294 l-3 -266 -144
            0 c-79 0 -156 -5 -172 -11 -56 -21 -102 -61 -128 -112 -22 -45 -25 -63 -25
            -164 0 -100 2 -115 20 -133 11 -11 29 -20 40 -20 11 0 29 9 40 20 17 17 20 33
            20 120 0 55 5 111 12 125 25 56 9 55 747 55 l681 0 32 -29 33 -29 3 -654 2
            -653 -37 -25 c-55 -38 -120 -132 -144 -211 l-21 -69 -57 0 c-85 0 -91 -10 -91
            -153 l0 -117 -74 0 c-112 0 -106 23 -106 -392 l0 -348 -490 0 -490 0 0 1060 0
            1060 490 0 490 0 0 -610 c0 -597 0 -610 20 -630 25 -25 55 -25 80 0 20 20 20
            33 20 674 l0 655 -22 15 c-20 14 -93 16 -590 16 -556 0 -568 0 -588 -20 -20
            -20 -20 -29 -18 -1333 l3 -1312 28 -57 c35 -72 93 -130 165 -165 l57 -28 1549
            -3 1549 -2 44 21 c27 13 55 37 71 62 l27 40 3 239 3 238 328 0 c317 0 329 -1
            355 -21 l26 -20 0 -200 c0 -186 -1 -200 -20 -219 -19 -19 -33 -20 -273 -20
            -280 0 -297 -3 -297 -60 0 -57 16 -60 304 -60 244 0 262 1 304 21 26 13 54 38
            70 62 27 40 27 42 30 251 2 142 -1 225 -9 254 -13 46 -71 107 -114 118 -22 6
            -25 11 -25 55 l0 49 49 0 c38 0 56 6 75 22 24 23 25 23 160 1418 147 1510 158
            1658 116 1700 -20 20 -33 20 -800 20 -767 0 -780 0 -800 -20 -38 -38 -28 -212
            65 -1164 47 -476 85 -872 85 -879 -1 -6 -12 14 -25 46 -13 32 -44 88 -69 125
            -57 86 -335 362 -365 362 -14 0 -59 -35 -120 -92 -108 -103 -173 -145 -291
            -193 -44 -18 -86 -38 -95 -45 -40 -33 3 -107 56 -96 104 20 290 127 391 224
            l60 57 127 -130 c162 -165 217 -263 246 -432 l7 -43 -746 0 -746 0 0 23 c0 32
            53 119 92 152 51 43 108 64 230 85 67 12 118 26 129 36 42 37 10 94 -52 94
            -19 0 -72 -7 -119 -15 -47 -8 -86 -15 -87 -15 -1 0 -4 285 -5 633 -3 629 -3
            632 -25 673 -28 53 -83 100 -130 113 -21 6 -102 11 -180 11 l-143 0 0 263 c0
            300 1 297 -78 297 l-42 0 0 110 0 109 311 3 c294 3 311 4 325 22 10 15 14 53
            14 148 0 115 -2 130 -20 148 -20 20 -33 20 -643 20 -522 0 -626 -2 -643 -14z
            m1186 -156 l0 -50 -542 2 -543 3 -3 48 -3 47 545 0 546 0 0 -50z m-650 -280
            l0 -110 -160 0 -160 0 0 110 0 110 160 0 160 0 0 -110z m120 -450 l0 -220
            -280 0 -280 0 0 220 0 220 280 0 280 0 0 -220z m3330 75 l0 -75 -458 0 c-398
            0 -460 -2 -480 -16 -26 -18 -29 -60 -5 -86 15 -17 43 -18 475 -18 l458 0 -1
            -32 c0 -18 -57 -613 -127 -1323 l-127 -1290 -358 -3 -357 -2 0 223 c0 274 3
            267 -106 267 l-74 0 0 119 c0 114 -1 120 -24 138 -23 19 -25 33 -115 948 -50
            512 -91 936 -91 943 0 9 36 12 144 12 138 0 146 1 160 22 21 30 20 54 -4 78
            -18 18 -33 20 -165 20 l-145 0 0 75 0 75 700 0 700 0 0 -75z m-1280 -2280 l0
            -75 -900 0 -900 0 0 75 0 75 900 0 900 0 0 -75z m180 -765 c0 -557 0 -570 -20
            -590 -20 -20 -33 -20 -1060 -20 -1027 0 -1040 0 -1060 20 -16 16 -20 33 -20
            80 l0 60 830 0 c817 0 830 0 850 20 19 19 20 33 20 410 0 377 -1 391 -20 410
            -20 20 -33 20 -850 20 l-830 0 0 80 0 80 1080 0 1080 0 0 -570z m-580 -20 l0
            -310 -790 0 -790 0 0 310 0 310 790 0 790 0 0 -310z m1260 55 l0 -45 -280 0
            -280 0 0 45 0 45 280 0 280 0 0 -45z m-2960 -402 c0 -109 3 -208 6 -220 l6
            -23 -410 0 -410 0 -53 28 c-39 20 -62 41 -84 77 -29 47 -30 52 -33 192 l-3
            143 490 0 491 0 0 -197z"/>
            <path d="M3617 3502 c-15 -16 -17 -46 -17 -210 0 -179 1 -193 20 -212 19 -19
            33 -20 420 -20 387 0 401 1 420 20 19 19 20 33 20 213 0 175 -2 196 -18 210
            -17 15 -61 17 -424 17 -380 0 -406 -1 -421 -18z m743 -212 l0 -110 -320 0
            -320 0 0 110 0 110 320 0 320 0 0 -110z"/>
            </g>
            </svg>
          </div>
          <span>SKIN BABY</span>
        </NavLink>
        <NavLink
          className="mobileLinks"
          to="/#aboutUs"
          end
        >
          <div className="iconContainer">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#cca352" stroke="none">
            <path d="M883 5096 l-28 -24 -3 -2451 -2 -2451 -396 0 -396 0 -29 -29 c-38
            -38 -38 -74 0 -112 l29 -29 2502 0 2502 0 29 29 c38 38 38 74 0 112 l-29 29
            -271 0 -271 0 0 1425 0 1426 -25 24 -24 25 -616 0 -615 0 0 998 c-1 768 -3
            1001 -13 1013 -30 39 -20 39 -1186 39 l-1130 0 -28 -24z m2187 -1086 l0 -940
            -439 0 -440 0 -28 -24 -28 -24 -3 -1426 -2 -1426 -85 0 -85 0 0 413 0 412 -24
            50 c-28 60 -66 97 -126 124 -39 18 -66 21 -191 21 -136 0 -148 -2 -197 -26
            -29 -14 -65 -42 -82 -62 -58 -71 -60 -86 -60 -529 l0 -403 -130 0 -130 0 0
            2390 0 2390 1025 0 1025 0 0 -940z m1280 -2475 l0 -1365 -1025 0 -1025 0 0
            1365 0 1365 1025 0 1025 0 0 -1365z m-2585 -540 l25 -24 0 -401 0 -400 -170 0
            -170 0 0 390 c0 326 2 396 15 420 8 15 20 30 27 33 7 3 66 6 131 6 112 1 119
            0 142 -24z"/>
            <path d="M1334 4770 c-52 -21 -54 -34 -54 -328 0 -285 5 -320 45 -336 9 -3
            145 -6 301 -6 l285 0 24 25 25 24 0 288 c-1 302 -1 307 -47 331 -26 14 -546
            16 -579 2z m456 -330 l0 -170 -170 0 -170 0 0 170 0 170 170 0 170 0 0 -170z"/>
            <path d="M2178 4764 c-44 -24 -50 -63 -46 -351 3 -261 3 -261 27 -287 l24 -26
            292 0 292 0 24 26 c24 26 24 26 27 287 4 292 -1 328 -48 352 -42 22 -552 21
            -592 -1z m470 -326 l2 -168 -175 0 -175 0 0 163 c0 90 3 167 7 171 4 3 81 5
            172 4 l166 -3 3 -167z"/>
            <path d="M1333 3916 c-17 -8 -36 -23 -42 -35 -7 -13 -11 -119 -11 -300 l0
            -280 24 -28 24 -28 290 0 290 0 26 24 26 24 0 288 0 288 -24 28 -24 28 -274 2
            c-217 2 -279 0 -305 -11z m457 -336 l0 -170 -170 0 -170 0 0 170 0 170 170 0
            170 0 0 -170z"/>
            <path d="M2210 3924 c-42 -15 -60 -30 -70 -57 -6 -18 -10 -133 -10 -284 0
            -286 5 -312 60 -332 51 -17 531 -15 575 3 52 22 57 52 53 353 l-3 263 -28 27
            -27 28 -268 2 c-147 1 -274 0 -282 -3z m438 -341 l-3 -168 -170 0 -170 0 -3
            168 -2 167 175 0 175 0 -2 -167z"/>
            <path d="M1319 3057 c-36 -28 -39 -51 -39 -332 l0 -277 29 -29 29 -29 285 0
            284 0 27 26 26 27 0 289 0 289 -25 24 -24 25 -288 0 c-211 -1 -292 -4 -304
            -13z m471 -327 l0 -170 -170 0 -170 0 0 170 0 170 170 0 170 0 0 -170z"/>
            <path d="M1334 2210 c-52 -21 -54 -34 -54 -328 0 -285 5 -320 45 -336 9 -3
            145 -6 301 -6 l285 0 24 25 25 24 0 288 c-1 302 -1 307 -47 331 -26 14 -546
            16 -579 2z m456 -330 l0 -170 -170 0 -170 0 0 170 0 170 170 0 170 0 0 -170z"/>
            <path d="M2589 2701 l-29 -29 0 -277 c0 -281 3 -304 39 -332 12 -9 93 -12 304
            -13 l288 0 24 25 25 24 0 289 0 289 -26 27 -27 26 -284 0 -285 0 -29 -29z
            m481 -311 l0 -170 -170 0 -170 0 0 170 0 170 170 0 170 0 0 -170z"/>
            <path d="M3443 2706 l-28 -24 0 -292 0 -292 28 -24 28 -24 284 0 284 0 28 24
            28 24 3 259 c2 142 0 273 -3 292 -3 18 -17 44 -30 57 l-24 24 -285 0 -285 0
            -28 -24z m487 -316 l0 -170 -175 0 -175 0 0 170 0 170 175 0 175 0 0 -170z"/>
            <path d="M2614 1870 c-52 -21 -54 -33 -54 -338 l0 -283 24 -24 c13 -13 39 -27
            57 -30 19 -3 150 -5 292 -3 l259 3 24 28 24 28 0 288 0 288 -26 24 -26 24
            -276 2 c-152 1 -287 -2 -298 -7z m456 -330 l0 -170 -170 0 -170 0 0 170 0 170
            170 0 170 0 0 -170z"/>
            <path d="M3464 1866 c-20 -9 -35 -25 -43 -46 -14 -43 -15 -525 -1 -567 20 -55
            36 -58 341 -58 l279 0 27 28 28 27 3 263 c4 301 -1 331 -53 353 -48 20 -535
            20 -581 0z m464 -328 l2 -168 -175 0 -175 0 0 163 c0 90 3 167 7 171 4 3 81 5
            172 4 l166 -3 3 -167z"/>
            <path d="M2602 1013 c-7 -3 -19 -18 -27 -33 -12 -24 -15 -81 -15 -304 l0 -275
            24 -28 24 -28 274 -3 c150 -2 283 0 295 3 12 3 32 15 43 27 19 21 20 34 20
            310 l0 289 -25 24 -24 25 -288 -1 c-158 0 -294 -3 -301 -6z m468 -333 l0 -170
            -170 0 -170 0 0 170 0 170 170 0 170 0 0 -170z"/>
            <path d="M3439 994 c-24 -26 -24 -26 -27 -287 -4 -292 1 -328 48 -352 43 -22
            547 -22 590 0 47 24 52 60 48 352 -3 261 -3 261 -27 287 l-24 26 -292 0 -292
            0 -24 -26z m489 -311 l-3 -168 -170 0 -170 0 -3 168 -2 167 175 0 175 0 -2
            -167z"/>
            </g>
            </svg>
          </div>
          <span>Sobre nós</span>
        </NavLink>
        <NavLink
          className={({ isActive }) => "mobileLinks" + (isActive ? " mobSelected" : "")}
          to="/sobre-mim/#top"
        >
          <div className="iconContainer">
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#cca352" stroke="none">
              <path d="M2610 5025 c-47 -8 -112 -24 -145 -36 -42 -15 -87 -23 -150 -24 -122
              -4 -210 -27 -340 -90 -224 -109 -397 -287 -554 -570 -118 -212 -195 -440 -266
              -785 -24 -118 -73 -336 -109 -485 -182 -761 -275 -1334 -294 -1820 l-8 -187
              -39 -31 c-22 -17 -86 -76 -141 -131 -199 -198 -304 -410 -321 -648 -6 -83 -5
              -87 20 -112 33 -33 79 -34 111 -3 19 19 24 38 29 109 22 288 213 548 551 753
              79 48 116 85 116 116 0 9 -12 32 -27 49 -22 27 -32 31 -62 28 -20 -3 -44 -8
              -54 -12 -15 -7 -17 -2 -17 50 0 75 25 361 46 524 40 314 119 739 219 1180 30
              129 81 362 115 517 83 388 137 557 240 762 204 407 521 653 807 627 64 -6 83
              -3 144 19 39 14 109 32 156 40 385 67 678 -108 912 -546 187 -351 330 -943
              361 -1499 21 -367 31 -490 50 -645 62 -501 58 -568 -55 -905 -80 -237 -112
              -362 -131 -500 -26 -198 -13 -343 42 -467 27 -62 28 -61 -80 -33 -123 31 -209
              81 -306 179 -73 73 -94 101 -138 191 -108 220 -155 480 -169 940 -6 206 -7
              215 -29 237 -32 32 -78 30 -111 -3 l-26 -26 7 -217 c13 -451 66 -742 176 -977
              138 -296 371 -464 700 -504 176 -21 221 -10 234 55 6 25 -3 44 -48 113 -88
              133 -100 173 -101 332 0 184 21 284 121 594 107 327 118 391 110 601 -3 88
              -13 216 -21 285 -8 69 -22 181 -30 250 -9 69 -20 233 -25 364 -18 436 -72 797
              -170 1145 -190 673 -501 1082 -900 1185 -90 24 -293 29 -400 11z"/>
              <path d="M2107 4227 c-10 -7 -24 -40 -31 -77 -30 -143 -114 -277 -264 -422
              -109 -105 -200 -170 -275 -195 -76 -26 -129 -83 -151 -163 -67 -239 6 -499
              167 -590 52 -29 53 -30 82 -122 39 -126 130 -305 209 -409 232 -307 553 -455
              854 -393 343 70 661 394 788 803 26 85 32 95 65 111 53 27 122 102 153 166 70
              144 73 353 6 485 -34 67 -76 85 -260 114 -420 68 -947 352 -1211 653 -39 44
              -52 52 -81 52 -19 0 -42 -6 -51 -13z m284 -394 c323 -237 740 -417 1084 -466
              84 -12 91 -14 105 -42 22 -45 20 -215 -4 -283 -23 -69 -78 -126 -133 -141 -54
              -15 -80 -42 -89 -95 -3 -22 -19 -81 -35 -130 -130 -398 -477 -694 -789 -673
              -146 9 -307 88 -442 215 -162 153 -248 311 -327 598 -13 49 -30 65 -93 88 -97
              36 -143 130 -143 291 1 135 10 150 117 202 208 100 453 349 538 548 l20 47 48
              -44 c27 -25 91 -76 143 -115z"/>
              <path d="M1245 2318 c-11 -6 -25 -19 -32 -27 -11 -16 -52 -210 -75 -366 -18
              -119 -15 -501 5 -670 40 -333 47 -409 53 -570 6 -140 4 -181 -10 -233 -21 -82
              -59 -159 -108 -218 -35 -42 -39 -53 -34 -84 11 -70 57 -82 236 -60 510 63 780
              421 854 1130 18 170 32 545 22 584 -5 19 -18 32 -41 42 -31 12 -39 12 -70 0
              -22 -9 -36 -23 -39 -38 -3 -13 -7 -115 -11 -228 -20 -724 -164 -1097 -480
              -1246 -69 -33 -171 -64 -208 -64 -22 0 -22 -5 20 110 28 74 28 78 27 285 -1
              122 -9 267 -18 345 -9 74 -24 198 -33 275 -21 174 -23 503 -4 630 7 50 24 144
              38 209 26 128 25 154 -9 186 -24 22 -53 25 -83 8z"/>
              <path d="M4193 1119 c-45 -17 -68 -86 -40 -121 6 -8 48 -39 91 -69 99 -67 230
              -190 296 -278 61 -80 134 -224 155 -306 9 -33 18 -96 22 -140 5 -64 11 -84 29
              -102 32 -31 78 -30 111 3 25 25 26 29 20 108 -23 326 -227 638 -562 863 -68
              45 -92 53 -122 42z"/>
              <path d="M2127 713 c-26 -7 -49 -59 -42 -95 10 -42 190 -223 280 -281 92 -58
              169 -97 195 -97 28 0 108 41 206 106 96 63 259 230 269 274 8 39 -17 86 -51
              94 -41 11 -76 -9 -120 -66 -51 -68 -168 -167 -244 -207 l-61 -32 -63 35 c-82
              46 -175 124 -233 195 -60 75 -84 88 -136 74z"/>
              </g>
              </svg>
          </div>
          <span>Sobre mim</span>
        </NavLink>
        <NavLink
          className={({ isActive }) => "mobileLinks" + (isActive ? " mobSelected" : "")}
          to="/depoimentos/#top"
        >
          <div className="iconContainer">
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#cca352" stroke="none">
              <path d="M2449 4995 c-66 -21 -138 -72 -177 -125 -18 -25 -159 -301 -314 -615
              -154 -313 -289 -582 -300 -598 -11 -15 -33 -34 -48 -42 -17 -9 -304 -55 -679
              -110 -415 -60 -668 -101 -701 -114 -193 -73 -283 -308 -190 -498 27 -54 81
              -111 443 -463 554 -541 554 -541 567 -588 9 -35 -4 -122 -100 -681 -60 -353
              -110 -664 -110 -691 0 -244 261 -425 486 -338 33 12 312 156 620 318 536 283
              561 295 614 295 53 0 78 -12 614 -295 308 -162 587 -306 620 -318 85 -33 196
              -28 279 13 120 59 207 195 207 322 0 29 -50 341 -110 694 -96 556 -109 646
              -100 680 13 48 11 46 557 579 371 361 426 419 453 473 93 190 3 424 -191 498
              -34 13 -279 53 -701 115 -374 54 -661 100 -678 109 -15 8 -37 27 -48 42 -11
              16 -144 282 -297 593 -153 311 -291 584 -306 606 -65 95 -161 146 -284 151
              -51 2 -97 -3 -126 -12z m192 -211 c15 -7 36 -26 47 -41 10 -16 144 -282 297
              -593 153 -311 291 -584 308 -608 32 -48 91 -95 153 -123 27 -12 254 -49 694
              -113 470 -69 663 -101 689 -114 73 -38 104 -138 66 -212 -8 -16 -225 -234
              -482 -485 -257 -251 -480 -475 -496 -497 -35 -52 -57 -127 -57 -192 0 -28 50
              -339 110 -692 75 -437 108 -652 103 -674 -8 -44 -79 -115 -122 -123 -19 -4
              -51 -2 -72 3 -21 6 -291 144 -600 306 -310 163 -581 303 -603 311 -52 19 -179
              19 -231 0 -22 -8 -294 -148 -603 -310 -310 -163 -580 -301 -601 -307 -21 -5
              -53 -7 -72 -3 -43 8 -114 79 -122 122 -4 20 9 121 34 269 22 130 71 419 110
              642 38 223 69 428 69 456 0 65 -22 140 -57 192 -16 22 -238 246 -496 497 -257
              251 -474 469 -482 485 -38 74 -7 174 66 212 26 13 219 45 690 114 728 105 719
              103 810 194 37 38 69 91 152 261 337 688 487 986 505 1002 50 45 130 54 193
              21z"/>
              </g>
              </svg>
          </div>
          <span>Depoimentos</span>
        </NavLink>
        <NavLink
          className={({ isActive }) => "mobileLinks" + (isActive ? " mobSelected" : "")}
          to="/#contact"
        >
          <div className="iconContainer">
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#cca352" stroke="none">
              <path d="M193 4256 c-94 -30 -161 -98 -183 -187 -7 -31 -10 -488 -8 -1534 l3
              -1490 30 -54 c17 -30 48 -67 69 -83 86 -62 -81 -58 2456 -58 2537 0 2370 -4
              2456 58 21 16 53 53 69 83 l30 54 0 1515 0 1515 -30 54 c-16 30 -48 67 -69 83
              -86 62 82 58 -2460 57 -1880 0 -2329 -3 -2363 -13z m4547 -200 c-8 -7 -475
              -358 -1037 -779 -685 -514 -1034 -769 -1060 -776 -49 -14 -117 -14 -166 0 -26
              7 -375 262 -1060 776 -562 421 -1029 772 -1037 779 -12 12 314 14 2180 14
              1866 0 2192 -2 2180 -14z m-3460 -921 c580 -435 1074 -799 1098 -808 24 -10
              74 -23 112 -29 84 -14 187 0 265 36 27 13 525 380 1105 815 l1055 792 3 -685
              c1 -377 1 -994 0 -1370 l-3 -685 -555 685 c-305 377 -562 690 -571 695 -27 14
              -77 11 -104 -7 -28 -19 -47 -67 -39 -101 4 -14 260 -339 570 -722 l564 -696
              -1110 -3 c-611 -1 -1609 -1 -2220 0 l-1110 3 564 696 c310 383 566 708 570
              722 8 34 -11 82 -39 101 -25 16 -71 21 -100 10 -8 -4 -264 -313 -567 -688
              -303 -374 -555 -685 -559 -690 -5 -6 -9 584 -9 1363 0 1095 3 1371 13 1365 6
              -5 487 -364 1067 -799z"/>
              </g>
              </svg>
          </div>
          <span>Contato</span>
        </NavLink>
      </div>
    </Styles>
  );
};

export default NavBar;
